<template lang="html">
  <div class="card card-primary card-outline OrderList">
    <div class="card-header p-1">
      <div class="card-title">
        <button type="button" class="btn btn-default btn-sm mx-1 mr-2" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
        <button type="button" v-if="tabled" class="btn btn-default btn-sm mx-1 mr-2" v-on:click="tabled=false" title="切换卡片列表"><i class="fas fa-th-large"></i></button>
        <button type="button" v-else class="btn btn-default btn-sm mx-1 mr-2" v-on:click="tabled=true" title="切换表格列表"><i class="fas fa-th-list"></i></button>
        <!--<div class="btn-group" role="group" aria-label="Basic example">
            <router-link class="btn btn-secondary" :to="{ name: 'OrderList', query: null}">
                全部
            </router-link>
            <router-link class="btn btn-secondary" v-for="i in types" :to="{ name: 'OrderList', query: { type: i.key }}">
                {{i.key}}
            </router-link>
        </div>-->
      </div>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm mt-0">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body p-1">
      <div class="mailbox-controls p-0">
        <ul class="nav nav-tabs nav-nowrap">
          <li class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.state==null}" :to="{ query: {...$route.query,page:undefined, state: undefined }}">
              全部
            </router-link>
          </li>
          <li v-for="i in states" class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.state==i.value}" :to="{ query: {...$route.query,page:undefined, state: i.value }}">
              {{i.key}}
            </router-link>
          </li>
        </ul>
        <!--<div class="form-inline visible-md-inline-block visible-lg-inline-block visible-sm-inline-block">
            <div class="form-group form-group-sm">
                <label>类型</label>
                <select class="form-control" v-model:value="query.type" v-on:change="search()" placeholder="类型">
                    <option value="">所有</option>
                    <option v-for="i in types" v-bind:value="i">{{i.key}}</option>
                </select>
            </div>
        </div>-->
        <!--<div class="pull-right">
            <div class="form-inline">
                <div class="form-group form-group-sm">
                </div>
            </div>
        </div>-->
        <!-- /.pull-right -->
      </div>
      <div v-if="tabled">
        <vue-bootstrap4-table :rows="list"
                              :columns="columns"
                              :config="config"
                              :classes="classes"
                              @on-change-query="onChangeQuery">
          <template slot="sort-asc-icon">
            <i class="fas fa-sort-up"></i>
          </template>
          <template slot="sort-desc-icon">
            <i class="fas fa-sort-down"></i>
          </template>
          <template slot="no-sort-icon">
            <i class="fas fa-sort"></i>
          </template>
          <template slot="empty-results">
            没有数据
          </template>
          <template slot="active" slot-scope="props">
            <button class="btn btn-default btn-xs mx-1" title="查看" @click="showView(props.row)">
              <i class="fas fa-book-open"></i>
            </button>
            <button class="btn btn-default btn-xs mx-1" title="删除" @click="opendelete(props.row)">
              <i class="fas fa-trash"></i>
            </button>
          </template>
          <template slot="orderNo" slot-scope="props">
            <span class="text-nowrap" @click="showView(props.row)">{{props.row.orderNo}}</span>
          </template>
          <template slot="title" slot-scope="props">
            <span class="text-nowrap" @click="showView(props.row)">{{props.row.title}}</span>
          </template>
          <template slot="readed" slot-scope="props">
            {{props.row.readed ? "是" : "否"}}
          </template>
        </vue-bootstrap4-table>
      </div>
      <div v-else>
        <div class="orderCard row">
          <div v-for="item in list" class="col-sm-6 col-md-6 col-lg-4 col-xl-3 my-2" @click="showView(item)">
            <div class="card" style="height:100%">
              <div class="card-body" style="position:relative">
                <div style="display:flex;justify-content:space-between;">
                  <h5 style="word-break: break-all;">
                    {{item.title}}
                  </h5>
                  <span style="width:30px;flex:none">
                    <!--<button class="btn btn-default btn-xs mx-1" title="查看" @click="showView(props.row)">
                        <i class="fas fa-book-open"></i>
                    </button>-->
                    <button class="btn btn-danger btn-xs mx-1" title="删除" @click="opendelete(props.row)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </span>
                </div>
                <h6>订单号：{{item.orderNo}}</h6>
                <!--<div style="display:flex;justify-content:space-between;flex-wrap: wrap;">-->
                <div class="card-text">金额：￥{{item.totalPrice}}</div>
                <div class="card-text">时间：{{item.addTime}}</div>
                <div class="card-text">状态：{{item.stateName}}</div>
                <!--</div>-->
              </div>
            </div>
          </div>
          <div></div><!--占位用-->
          <div></div><!--占位用-->
          <div></div><!--占位用-->
          <div></div><!--占位用-->
          <div></div><!--占位用-->
          <div></div><!--占位用-->
        </div>
      </div>
    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="this.$store.state.loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <!-- end loading -->
    <div class="card-footer p-2">
      <paging :page-index="list.pageIndex"
              :total="list.totalRecords"
              :page-size="list.pageSize"
              :per-pages="3"
              :show-go="true"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <Modal ref="View">
      <template slot="header">
        <h4 v-if="item" class="modal-title">{{item.title}}</h4>
      </template>
      <form class="form-horizontal">
        <div v-if="item">
          <h6>订单号：{{item.orderNo}}</h6>
          <div>金额：￥{{item.totalPrice}}</div>
          <div>时间：{{item.addTime}}</div>
          <div>状态：{{item.stateName}}</div>
          <div v-if="item.state>1">
            <hr />
            <div>支付时间：{{item.receipt.payTime}}</div>
            <div>支付方式：{{item.receipt.payType}}</div>
            <div>支付状态：{{item.receipt.state}}</div>
            <div>支付金额：{{item.receipt.totalPrice}}</div>
          </div>
        </div>
        <div v-else>
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" @click="$refs.View.hide()">关闭</button>
        <button v-if="item&&item.state==0" type="button" class="btn btn-primary pull-right" @click="pay(item)">支付</button>
      </template>
    </Modal>
    <Modal ref="Delete">
      <template slot="header">
        <h4 class="modal-title">删除订单</h4>
      </template>
      <form class="form-horizontal">
        {{item}}
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" @click="$refs.Delete.hide()">取消</button>
        <button type="button" class="btn btn-danger" @click="del(item)" :disabled="updateing">删除</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { Modal } from 'common'
  //import Modal from '@/components/Modal'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    components: {
      Modal
    },
    computed: {
      ...mapGetters([
        'setting',
      ])
    },
    data() {
      return {
        list: [],
        item: {
          guid: null,
          name: null,
          closed: false,
          type: '默认',
        },
        columns: [
          { name: 'orderNo', label: '订单号', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'title', label: '标题', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'type', label: '类型', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_type" },
          { name: 'totalPrice', label: '金额', sort: false, initial_sort_order: 'desc', column_classes: "text-nowrap c_type" },
          { name: 'addTime', label: '创建时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'stateName', label: '订单状态', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
          { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: { ...this.getDefaultQuery(), ...this.$route.query },
        types: [],
        states: [
          { "key": "待付款", "value": 0 },
          //{ "key": "已付款", "value": 1 },
          //{ "key": "已发货", "value": 2 },
          //{ "key": "已收货", "value": 3 },
          //{ "key": "申请退款", "value": 5 },
          //{ "key": "确认退款", "value": 6 },
          //{ "key": "退款完成", "value": 7 },
          { "key": "完成", "value": 10 },
          { "key": "订单关闭", "value": -1 }
        ],
        updateing: false,
        tabled: false,
      }
    },
    created() {
      this.loadData()
      this.loadTypes()
      //this.loadStates()
      //this.changeState(this.$route.query.state)
      //this.query.readed = this.$route.query.readed
    },
    methods: {
      rowClass(item, type) {
        if (!item) return
        if (item.readed === false) return 'text-bold'
      },
      loadTypes() {
        this.$axios.get('/Api/My/Orders/Types').then((res) => {
          this.types = res.data
          //this.changeType(this.$route.query.type)
        })
      },
      loadStates() {
        this.$axios.get('/Api/My/Orders/States').then((res) => {
          this.states = res.data
          //this.changeState(this.$route.query.state)
        })
      },
      //changeType(typestr) {
      //    for (var i in this.types) {
      //        if (this.types[i].key == typestr) {
      //            this.query.type = this.types[i]
      //            this.search()
      //            return;
      //        }
      //    }
      //    this.query.type = null
      //    this.search()
      //},
      //changeState(statestr) {
      //    for (var i in this.states) {
      //        if (this.states[i].key == statestr) {
      //            this.query.state = this.states[i]
      //            this.search()
      //            return;
      //        }
      //    }
      //    this.query.state = null
      //    this.search()
      //},
      loadData() {
        var url = "/Api/My/Orders"
        var params = {
          ...this.query
        }
        this.$axios.get(url, { params: params })
          .then((response) => {
            console.log(response)
            this.list = response.data.data
            this.pager.pageIndex = response.data.pageIndex
            this.pager.pageSize = response.data.pageSize
            this.pager.totalPages = response.data.totalPages
            this.pager.totalRecords = response.data.totalRecords

          }).catch((error) => {
            console.log(error)
          })

      },
      loadItem(id) {
        this.item = null;
        this.$axios.get(`/Api/My/Orders/${id}`)
          .then((response) => {
            //console.log(response)
            this.item = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      showView(item) {
        this.$refs.View.show()
        this.loadItem(item.id)
      },
      getDefaultQuery() {
        return {
          pageIndex: 1,
          pageSize: 12,
          sort: "addTime",
          order: "desc",
          key: null,
          type: null,
          state: null,
        }
      },
      getDifferentQuery(query) {
        var dif = {}
        var all = { ...this.query, ...query }
        var def = this.getDefaultQuery()
        for (var i in all) {
          if (def[i] != all[i]) {
            dif[i] = all[i]
          }
        }
        return dif
      },
      setQuery(newquery) {
        if (!newquery) {
          this.query = this.getDefaultQuery()
        }
        var dif = this.getDifferentQuery(newquery)
        console.log(this.query, newquery, this.$route.query, dif)
        if (!this.$isObjectValueEqual(this.$route.query, dif)) {
          this.$router.push({ query: dif })
        }
      },
      pay(item) {
        location.href = `${this.setting.payUrl}/Pay/?orderNo=${item.orderNo}&returnUrl=${this.setting.myUrl}/Order`
      },
      search() {
        this.setQuery({ page: 1 })
      },
      pageChange(page, pageSize) {
        this.setQuery({ page, pageSize })
      },
      onChangeQuery(queryParams) {
        var sort = queryParams.sort[0]
        this.setQuery({ sort: sort.name, order: sort.order })
      },
      opendelete(item) {
        console.log(item)
        this.$refs.Delete.show()
        this.loadItem(item.id)
      },
      del(item) {
        this.updateing = true;
        console.log(item)
        this.$axios.delete(`/Api/My/Orders/${item.guid}`)
          .then((res) => {
            console.log(res)
            this.$refs.Delete.hide()
            this.loadData()
            this.updateing = false;
          })
          .catch((error) => {
            console.log(error)
            this.updateing = false;
          })
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.query = { ...this.getDefaultQuery(), ...to.query }
      this.loadData()
      next()
    }
  }
</script>
<style scoped>
  .orderCard {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

    .orderCard > div {
      /*margin: 5px;*/
      flex: auto;
      /*width: 300px;*/
      width: 100%;
    }

  .card-body {
    padding: 0.75rem;
  }
</style>
<style lang="css">
  .OrderList .c1 {
    width: 90px;
  }

  .OrderList .c2 {
    width: 60px;
  }

  .OrderList .c_dt {
    width: 155px;
  }

  .OrderList .c_type {
    width: 120px;
  }
</style>
